@import '../../styles/index';

$benefits-category-themes: (
  'surrogate': (
    color: $color-surrogate--100,
    filter: invert(19%) sepia(55%) saturate(3670%) hue-rotate(317deg)
      brightness(97%) contrast(108%),
  ),
  'red': (
    color: $color-red--100,
    filter: invert(17%) sepia(99%) saturate(5613%) hue-rotate(357deg)
      brightness(91%) contrast(121%),
  ),
  'orange': (
    color: $color-orange--100,
    filter: invert(55%) sepia(51%) saturate(5273%) hue-rotate(6deg)
      brightness(98%) contrast(101%),
  ),
  'green': (
    color: $color-green--100,
    filter: invert(57%) sepia(67%) saturate(517%) hue-rotate(52deg)
      brightness(95%) contrast(81%),
  ),
  'blue': (
    color: $color-blue--100,
    filter: invert(17%) sepia(89%) saturate(2477%) hue-rotate(215deg)
      brightness(87%) contrast(92%),
  ),
  'turquoise': (
    color: $color-turquoise--100,
    filter: invert(46%) sepia(47%) saturate(5189%) hue-rotate(155deg)
      brightness(92%) contrast(101%),
  ),
  'yellow': (
    color: $color-yellow--100,
    filter: invert(89%) sepia(28%) saturate(4784%) hue-rotate(357deg)
      brightness(100%) contrast(106%),
  ),
  'violet': (
    color: $color-violet--100,
    filter: invert(9%) sepia(91%) saturate(5749%) hue-rotate(296deg)
      brightness(102%) contrast(108%),
  ),
);

.base {
  position: relative;
  margin-bottom: 3rem;

  @include media-from(sm) {
    margin-bottom: 7.5rem;
  }

  .title {
    align-items: center;
    display: flex;
    font-size: $font-size-30;
    font-weight: $font-weight-regular;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 0;
    line-height: 1.1;
    gap: 0.75rem;
    text-align: center;

    @include media-from(sm) {
      flex-direction: row;
      font-size: $font-size-36;
      line-height: 1.2;
      text-align: left;
    }

    strong {
      font-weight: $font-weight-medium;
    }

    p {
      margin: 0;
    }
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .content-wrapper {
    width: 100%;
    max-width: $container-size-default;
    margin: 0 auto;
  }

  .grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    gap: 1.25rem;
  }
}

@each $theme, $map in $benefits-category-themes {
  .#{$theme} {
    .color-filter {
      filter: map-get($map, filter);
    }
  }
}
