@import '../../styles/index';

$stage-box-width: 600px !default;

.title {
  font-weight: $font-weight-bold;
  font-size: $font-size-30;
  line-height: 1.2;
  margin: 0 0 1rem 0;

  .white & {
    color: $color-surrogate--100;
    @extend %text-linear-gradient;
  }

  .gradient & {
    color: $color-white--100;
  }

  @include media-from(sm) {
    font-size: $font-size-48;

    .with-badge &::before {
      content: '';
      display: block;
      position: relative;
      float: right;
      width: $badge-sm - $stagebox-badge-shift-right -
        ($stagebox-text-padding-vertical * 0.5);
      height: 2rem;
    }
  }
}

.text {
  font-weight: $font-weight-regular;
  font-size: $font-size-18;
  line-height: 1.6;

  @include media-from(sm) {
    font-size: $font-size-20;
  }

  .white & {
    color: $color-black--100;
  }

  .gradient & {
    color: $color-white--100;
  }
}

.button-wrapper {
  margin-top: 1.25rem;
}

.with-badge {
  @include media-from(xs) {
    position: relative;
    padding-right: $badge-sm * 0.5;
  }
}

.stage-badge {
  position: absolute;
  right: 0;
  top: -$badge-xxs + 1.25rem;

  @include media-from(xs) {
    right: max(10%, calc(100% - $stage-box-width));
    top: -$stagebox-badge-shift-top;
  }

  @include media-from(sm) {
    right: calc(100% - $stage-box-width - $stagebox-badge-shift-right);
    top: -$stagebox-badge-shift-top;
  }
}

.stage-box {
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: $color-white--100;

  @include media-from(xs) {
    padding: 2rem $stagebox-text-padding-vertical 2rem;
  }

  &.special-article-styles {
    .title {
      font-size: $font-size-18;
      font-weight: $font-weight-medium;
      line-height: 1;

      @include media-from(xs) {
        font-size: $font-size-24;
      }

      &::after {
        margin-top: 1.25rem;
        content: '';
        position: relative;
        display: block;
        height: 5px;
        width: 50px;
        border-radius: $border-radius-small;
        background-color: $color-white--100;
      }
    }

    .text {
      font-size: $font-size-24;
      font-weight: $font-weight-bold;
      line-height: 1.2;

      @include media-from(xs) {
        font-size: 2.25rem;
      }
    }
  }
}
