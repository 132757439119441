@import '../../styles/index';

.container {
  margin: 0 auto;

  @include media-from(sm) {
    padding: 0 2rem;
  }

  @include media-from(md) {
    padding: 0 3rem;
  }
}

.base {
  max-width: 1100px;
  margin: 0 auto;
  background-color: $color-white--100;
  border-radius: $border-radius-small;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1.5rem 1rem;

  @include media-from(sm) {
    padding: 2rem 2.5rem;
  }
}

.title {
  margin-bottom: 1rem;
  max-width: 100%;
  text-align: left;
  margin-left: 0;

  @include media-from(sm) {
    text-align: center;
    margin-left: auto;
  }

  h3 {
    font-size: 1.75rem !important;
    line-height: 1.2;

    @include media-from(sm) {
      font-size: 2rem !important;
    }
  }

  & > * {
    font-weight: $font-weight-medium;
    font-size: $font-size-18;
    line-height: 1.6;

    @include media-from(sm) {
      font-size: 1.25rem;
    }
  }
}

.text {
  text-align: left;
  margin-bottom: 1rem;
  line-height: 1.6;

  @include media-from(sm) {
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

.button {
  margin-bottom: 1rem;
  @include media-from(sm) {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}
