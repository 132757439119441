@import '../../styles/index';

$offset: 1.5rem;

.base {
  position: absolute;
}

.popper {
  top: calc(100% - #{$offset});
  transform: translateX(-50%) scale(0.8);
  opacity: 0;
  transition: transform $duration-default, opacity $duration-default,
    color $duration-default, background-color $duration-default;
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius-default;
  font-size: $font-size-14;
  box-shadow: $shadow--small;
  min-width: max-content;

  &.show {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.default {
  color: $color-turquoise--100;
  background: $color-turquoise--10;
}

.highlight {
  color: $color-white--100;
  background: $color-turquoise--100;
  font-weight: $font-weight-medium;
}
