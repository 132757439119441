@import '../../styles/index';

$duration: 0.8s;
$offset: 1.125rem;
$peel-width: 0.25rem;
$ease: cubic-bezier(0.8, 0.9, 0.8, -0.2);

.base {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: $font-weight-regular;
  color: $color-surrogate--100;
  border-radius: $border-radius-full;
  width: 100%;
  background: none;
  cursor: pointer;

  @include media-from(sm) {
    max-width: 300px;
  }

  &:hover {
    .front {
      transform: translateX(-#{$peel-width});
      background-color: #b00b56;

      &:after {
        transform: translateX(calc(#{$offset} + #{$peel-width}));
      }

      &:before {
        transform: translateX(calc(100% - #{$offset} - #{$peel-width}));
      }
    }
  }

  &.large {
    font-size: $font-size-20;
    .back {
      padding: 0 1.5rem;
      height: 60px;
      // used folowing generator for the border: https://kovart.github.io/dashed-border-generator/
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23C7125CFF' stroke-width='4' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }
  }

  &.medium {
    font-size: $font-size-18;

    .back {
      padding: 0 1.25rem;
      height: 50px;
      // used folowing generator for the border: https://kovart.github.io/dashed-border-generator/
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23C7125CFF' stroke-width='4' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }
  }

  &.with-copy-icon {
    .back {
      justify-content: space-between;
    }
  }

  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background: $color-white--100;
    border-radius: $border-radius-full;

    transition: background-color $duration-default;

    @include media-from(sm) {
      max-width: 300px;
    }

    &.smaller-text {
      font-size: $font-size-14;
    }
  }

  .front {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    right: $offset;
    height: 100%;
    width: 100%;
    background: $color-surrogate--100;
    color: $color-white--100;
    white-space: nowrap;
    transition: transform $duration-default, background-color $duration-default;

    &:before {
      z-index: 2;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background: $color-surrogate--40;
      border-radius: 2em 0 0 2em;
      transform: translateX(calc(100% - #{$offset}));
      transition: transform $duration-default, box-shadow $ease $duration;
      box-shadow: -1em 0 2em rgba(0, 0, 0, 0.2);
    }

    &:after {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $font-weight-medium;
      content: attr(data-text);
      transform: translateX($offset);
      position: absolute;
      top: 0;
      left: $offset;
      right: 0;
      height: 100%;
      transition: transform $duration-default;
    }
  }

  &.peeled {
    .front {
      transition: transform $ease $duration;
      animation: peelFront $duration $ease forwards;

      &:after {
        transition: transform $ease $duration;
        animation: peelFrontAfter $duration $ease forwards;
      }

      &:before {
        transition: transform $ease $duration;
        animation: peelFrontBefore $duration $ease forwards;
        box-shadow: -2em 0 4em rgba(0, 0, 0, 0.3);
      }
    }

    .back {
      &:hover {
        background-color: $color-surrogate--10;
      }
    }
  }

  &.loading {
    .front {
      animation: front-loading 1.5s ease-in-out infinite;

      &:after {
        animation: front-loading-after 1.5s ease-in-out infinite;
      }

      &:before {
        animation: front-loading-before 1.5s ease-in-out infinite;
      }
    }
  }

  &.disabled {
    cursor: auto;

    .back {
      &:hover {
        background-color: $color-white--100;
      }
    }
  }
}

@keyframes peelFront {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes peelFrontAfter {
  0% {
    transform: translateX($offset);
  }
  100% {
    transform: translateX(calc(100% + #{$offset} + #{$offset}));
  }
}

@keyframes peelFrontBefore {
  0% {
    transform: translateX(calc(100% - #{$offset} - #{$peel-width}));
  }
  100% {
    transform: translateX(20%);
  }
}

@keyframes front-loading {
  0% {
    transform: translateX(-#{$peel-width});
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-#{$peel-width});
  }
}

@keyframes front-loading-after {
  0% {
    transform: translateX(calc(#{$offset} + #{$peel-width}));
  }
  50% {
    transform: translateX($offset);
  }
  100% {
    transform: translateX(calc(#{$offset} + #{$peel-width}));
  }
}

@keyframes front-loading-before {
  0% {
    transform: translateX(calc(100% - #{$offset} - #{$peel-width}));
  }
  50% {
    transform: translateX(calc(100% - #{$offset}));
  }
  100% {
    transform: translateX(calc(100% - #{$offset} - #{$peel-width}));
  }
}
