@import '../../styles/index';

.base {
  align-items: center;
  background-color: $color-white--100;
  border-radius: $border-radius-large;
  box-shadow: $shadow-benefits-deal-card;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 2.5rem 1.5rem 2rem;
  position: relative;
  width: 100%;
}

.iconWrapper {
  display: inline-block;
  height: 4rem;
  position: relative;
  transform: rotate(-5deg);
  width: 4rem;

  &:before {
    background: linear-gradient(
      90deg,
      $color-violet--100 -0.94%,
      $color-red--100 100%
    );
    border-radius: 100vw;
    content: '';
    filter: blur(40px);
    height: 8.625rem;
    left: 50%;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8.625rem;
    z-index: -1;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  margin-block: 1.5rem 0;
  text-align: center;
  text-wrap: pretty;
}

.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
