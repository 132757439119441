@import '../../styles/index';

.base {
  margin: 0;
}

.stage-button {
  color: $color-white--100;
}

.course-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.section-headline {
  margin-top: 0;
  font-size: $font-size-28;
  line-height: 1.35;
  font-weight: $font-weight-medium;
  @extend %text-linear-gradient;

  @include media-from(sm) {
    font-size: $font-size-36;
  }
}

.stage-box-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;

  @include media-from(sm) {
    gap: 2rem;
    padding: 2rem 2rem 0;
  }
}
