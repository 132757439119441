@import '../../../styles/index';

.base {
  background: $color-turquoise--100;
  border-radius: $border-radius-default;
  bottom: 1.5rem;
  box-shadow: $shadow--small;
  color: $color-white--100;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  opacity: 0;
  left: 50%;
  padding: 0.5rem 1.25rem;
  position: fixed;
  transform: translateX(-50%);
  transition: opacity $duration-default;
}

.show {
  opacity: 1;
}
