@use 'sass:math';
@import '../../styles/index';

$stage-box-width: 600px !default;
$skew-shift: 52px;
$responsive-skew-shift: min(
  calc(math.div($skew-shift, $stage-box-width) * 100vw),
  $skew-shift
);
$bottom-skew: polygon(
  0 0,
  100% 0,
  100% calc(100% - $responsive-skew-shift),
  0% 100%
);

.host {
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  width: 100%;

  @include media-from(xs) {
    width: calc(100% - 2rem);
  }
}

.base {
  position: relative;
  max-width: 100%;
  padding-bottom: 1rem;

  &.has-mobile-bottom-skew {
    clip-path: $bottom-skew;
    padding-bottom: calc(1rem + $responsive-skew-shift);
  }

  @include media-from(xs) {
    clip-path: $bottom-skew;
    padding-bottom: calc(1rem + $responsive-skew-shift);
  }

  &.gradient {
    background: $gradient-enercity;
  }

  &.white {
    background: $color-white--100;
  }

  &.has-top-line {
    &::before {
      background: $gradient-enercity;
      content: ' ';
      display: block;
      height: 0.5rem;
      position: relative;
      width: 100%;
    }
  }

  * {
    z-index: $index-stagebox-children;
  }

  button {
    z-index: $index-stagebox-button;
  }

  @include media-from(xs) {
    max-width: 90%;
    width: $stage-box-width;
  }

  @include media-from(md) {
    max-width: $stage-box-width;
    padding-bottom: $responsive-skew-shift;
  }
}

.shadow-host {
  filter: drop-shadow(0px 16px 48px rgba(0, 0, 0, 0.08));
}
